<template>
  <div class="container">
    <div class="header" @click="navTo('/me/profile')">
      <div class="avatar">
        <van-image :src="loginUser.avatar ? loginUser.avatar : logo" />
      </div>
      <div class="profile">
        <h3>{{ loginUser.showName ? loginUser.showName : "Hi,同学" }}</h3>
        <p>
          {{
            loginUser.orgFullPathName
              ? "班级：" + loginUser.orgFullPathName
              : "欢迎您"
          }}
        </p>
        <p>
          {{
            loginUser.orgMemo
              ? "QQ群：" + loginUser.orgMemo
              : "留住中国魂 做好国际人"
          }}
        </p>
      </div>
    </div>
    <van-divider
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      入学须知
    </van-divider>
    <van-grid clickable :column-num="4" :border="false">
      <van-grid-item to="/checkin/adviceNote" icon="font-o" text="录取通知书" />
      <van-grid-item to="/news/page?id=2" icon="peer-pay" text="报到指南" />
      <van-grid-item to="/checkin/step" icon="passed" text="报到流程" />
      <van-grid-item to="/index/contact" icon="service-o" text="联系我们" />
    </van-grid>
    <van-divider
      v-if="step != 'SUCCEED'"
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      在线报到
    </van-divider>
    <van-steps v-if="step != 'SUCCEED'" :active="active" active-color="#c12b35">
      <van-step>开始</van-step>
      <van-step>绑定新生</van-step>
      <van-step>学费缴纳</van-step>
      <van-step>选择宿舍</van-step>
      <!-- <van-step>宽带领取</van-step> -->
      <van-step>来校登记</van-step>
      <van-step>完成</van-step>
    </van-steps>
    <div class="action" v-if="loginUser.id">
      <van-button
        v-if="!loginUser.id"
        type="danger"
        block
        @click="navTo('/login')"
        >去登录</van-button
      >
      <div v-if="loginUser.id && step == 'PROFILE'" class="info">
        <p><strong>友情提示：</strong></p>
        <p>未找到您的录取信息，请修改个人资料。</p>
        <p>若有任何疑问，请随时联系招生办。</p>
        <p>招生办电话：0771-4797229，4797113，4797126</p>
      </div>
      <van-button
        v-if="step == 'PROFILE'"
        type="danger"
        block
        @click="navTo('/me/profile')"
        >去绑定身份</van-button
      >
      <van-button
        v-if="step == 'PAYMENT'"
        type="danger"
        block
        @click="navTo('/checkin/fee')"
        >去缴纳学费</van-button
      >
      <van-button
        v-if="step == 'HOSTEL'"
        type="danger"
        block
        @click="navTo('/checkin/hostel')"
        >去选择宿舍</van-button
      >
      <!-- /checkin?flow=visit -->
      <div v-if="step == 'VISIT' && !flow && !linkedAd">
        <van-button
          type="danger"
          block
          @click="
            hrefTo(
              'http://gx.10086.cn/gxwd/wdAdGoodsDetail/buyerIndex.html?2c94cba4821d400b01827b24e40c0160,shop_id=8a369643894612f0018957b578571080&flag=1&spreadQd=20_wechat_hd&touch=2020_other_touch&shareChannel=20_zhanggui_qd&ad_id=2c94beae82514c4701827b66aecd184d&urlBs=1957ba9fdf5c450a9179063c1d791aa3&isGridding=&busiOpId=&taskId=&sellerXyDist=&click=&jumpFlag=&assistantUserInfo='
            )
          "
          style="margin-bottom:10px;"
          >去宽带领取</van-button
        >
        <van-button type="default" block @click="navTo('/checkin/visit')"
          >去来校登记</van-button
        >
      </div>
      <div v-if="step == 'VISIT' && (flow || linkedAd)">
        <van-button type="danger" block @click="navTo('/checkin/visit')"
          >去来校登记</van-button
        >
      </div>
      <div v-if="step == 'SUCCEED'" class="succeed">
        <van-icon class="icon" name="smile" />
        <p>恭喜，您已完成在线报到</p>
      </div>
    </div>
    <div class="action" v-else>
      <div class="info">
        <p class="center">未找到您的录取信息，请注册或登录</p>
      </div>
      <van-button type="danger" block @click="navTo('/login')"
        >去注册或登录</van-button
      >
    </div>
    <van-divider
      v-if="step == 'SUCCEED'"
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      签到二维码
    </van-divider>
    <div v-if="step == 'SUCCEED'" class="qr">
      <vue-qr :text="api.getCheckInUrl() + loginUser.id" :size="200"></vue-qr>
    </div>
  </div>
</template>
<script>
import * as api from "@/apis/api";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      api,
      logo: require("@/assets/logo.png"),
      active: -1,
      step: "LOGIN",
      flow: this.$route.query["flow"],
      // linkedAd: localStorage.getItem("linkedAd"),
      linkedAd: true
    };
  },
  computed: {
    loginUser() {
      return this.$store.getters.user;
    },
  },
  created() {
    if (this.loginUser.id) {
      api.userProfile().then((res) => {
        this.$store.commit("setUser", res);
      });
      api.userStep().then((res) => {
        this.step = res.step;
        if (this.step == "PROFILE") {
          this.active = 0;
        } else if (this.step == "PAYMENT") {
          this.active = 1;
        } else if (this.step == "HOSTEL") {
          this.active = 2;
        } else if (this.step == "VISIT" && !this.flow && !this.linkedAd) {
          this.active = 3;
        } else if (this.step == "VISIT" && (this.flow || this.linkedAd)) {
          this.active = 4;
        } else if (this.step == "SUCCEED") {
          this.active = 99;
        }
      });
    }
  },
  methods: {
    navTo(url) {
      this.$router.push(url);
    },
    logout() {
      this.$store.commit("logout");
    },
    hrefTo(url) {
      localStorage.setItem("linkedAd", true);
      window.location.href = url;
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  background-color: #c12b35;
  padding: 10px;
  color: #fff;
}
.header .avatar {
  flex: 1;
}
.header .profile {
  flex: 3;
  margin-left: 10px;
}
.header .profile h3 {
  margin: 10px 0;
}
.header .profile p {
  margin: 5px 0;
  font-size: 14px;
}
.van-cell__left-icon {
  margin-right: 10px;
}
.action {
  padding: 10px;
}
.action .info {
  margin-bottom: 10px;
  background-color: #fefbea;
  color: #ed6a0c;
  font-size: 14px;
  padding: 6px 14px;
}
.action .info p {
  margin: 6px 0;
}
.action .info p.center {
  text-align: center;
}
.action .succeed {
  margin: 20px 0px;
  text-align: center;
}
.action .succeed .icon {
  font-size: 60px;
  color: #c12b35;
}
.action .succeed p {
  font-size: 14px;
}
.qr {
  padding: 0px 20px 60px 20px;
  text-align: center;
}
</style>
