var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header",on:{"click":function($event){return _vm.navTo('/me/profile')}}},[_c('div',{staticClass:"avatar"},[_c('van-image',{attrs:{"src":_vm.loginUser.avatar ? _vm.loginUser.avatar : _vm.logo}})],1),_c('div',{staticClass:"profile"},[_c('h3',[_vm._v(_vm._s(_vm.loginUser.showName ? _vm.loginUser.showName : "Hi,同学"))]),_c('p',[_vm._v(" "+_vm._s(_vm.loginUser.orgFullPathName ? "班级：" + _vm.loginUser.orgFullPathName : "欢迎您")+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.loginUser.orgMemo ? "QQ群：" + _vm.loginUser.orgMemo : "留住中国魂 做好国际人")+" ")])])]),_c('van-divider',{style:({
      borderColor: '#ee0a24',
      padding: '0 100px',
    })},[_vm._v(" 入学须知 ")]),_c('van-grid',{attrs:{"clickable":"","column-num":4,"border":false}},[_c('van-grid-item',{attrs:{"to":"/checkin/adviceNote","icon":"font-o","text":"录取通知书"}}),_c('van-grid-item',{attrs:{"to":"/news/page?id=2","icon":"peer-pay","text":"报到指南"}}),_c('van-grid-item',{attrs:{"to":"/checkin/step","icon":"passed","text":"报到流程"}}),_c('van-grid-item',{attrs:{"to":"/index/contact","icon":"service-o","text":"联系我们"}})],1),(_vm.step != 'SUCCEED')?_c('van-divider',{style:({
      borderColor: '#ee0a24',
      padding: '0 100px',
    })},[_vm._v(" 在线报到 ")]):_vm._e(),(_vm.step != 'SUCCEED')?_c('van-steps',{attrs:{"active":_vm.active,"active-color":"#c12b35"}},[_c('van-step',[_vm._v("开始")]),_c('van-step',[_vm._v("绑定新生")]),_c('van-step',[_vm._v("学费缴纳")]),_c('van-step',[_vm._v("选择宿舍")]),_c('van-step',[_vm._v("来校登记")]),_c('van-step',[_vm._v("完成")])],1):_vm._e(),(_vm.loginUser.id)?_c('div',{staticClass:"action"},[(!_vm.loginUser.id)?_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/login')}}},[_vm._v("去登录")]):_vm._e(),(_vm.loginUser.id && _vm.step == 'PROFILE')?_c('div',{staticClass:"info"},[_vm._m(0),_c('p',[_vm._v("未找到您的录取信息，请修改个人资料。")]),_c('p',[_vm._v("若有任何疑问，请随时联系招生办。")]),_c('p',[_vm._v("招生办电话：0771-4797229，4797113，4797126")])]):_vm._e(),(_vm.step == 'PROFILE')?_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/me/profile')}}},[_vm._v("去绑定身份")]):_vm._e(),(_vm.step == 'PAYMENT')?_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/checkin/fee')}}},[_vm._v("去缴纳学费")]):_vm._e(),(_vm.step == 'HOSTEL')?_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/checkin/hostel')}}},[_vm._v("去选择宿舍")]):_vm._e(),(_vm.step == 'VISIT' && !_vm.flow && !_vm.linkedAd)?_c('div',[_c('van-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.hrefTo(
            'http://gx.10086.cn/gxwd/wdAdGoodsDetail/buyerIndex.html?2c94cba4821d400b01827b24e40c0160,shop_id=8a369643894612f0018957b578571080&flag=1&spreadQd=20_wechat_hd&touch=2020_other_touch&shareChannel=20_zhanggui_qd&ad_id=2c94beae82514c4701827b66aecd184d&urlBs=1957ba9fdf5c450a9179063c1d791aa3&isGridding=&busiOpId=&taskId=&sellerXyDist=&click=&jumpFlag=&assistantUserInfo='
          )}}},[_vm._v("去宽带领取")]),_c('van-button',{attrs:{"type":"default","block":""},on:{"click":function($event){return _vm.navTo('/checkin/visit')}}},[_vm._v("去来校登记")])],1):_vm._e(),(_vm.step == 'VISIT' && (_vm.flow || _vm.linkedAd))?_c('div',[_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/checkin/visit')}}},[_vm._v("去来校登记")])],1):_vm._e(),(_vm.step == 'SUCCEED')?_c('div',{staticClass:"succeed"},[_c('van-icon',{staticClass:"icon",attrs:{"name":"smile"}}),_c('p',[_vm._v("恭喜，您已完成在线报到")])],1):_vm._e()],1):_c('div',{staticClass:"action"},[_vm._m(1),_c('van-button',{attrs:{"type":"danger","block":""},on:{"click":function($event){return _vm.navTo('/login')}}},[_vm._v("去注册或登录")])],1),(_vm.step == 'SUCCEED')?_c('van-divider',{style:({
      borderColor: '#ee0a24',
      padding: '0 100px',
    })},[_vm._v(" 签到二维码 ")]):_vm._e(),(_vm.step == 'SUCCEED')?_c('div',{staticClass:"qr"},[_c('vue-qr',{attrs:{"text":_vm.api.getCheckInUrl() + _vm.loginUser.id,"size":200}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("友情提示：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',{staticClass:"center"},[_vm._v("未找到您的录取信息，请注册或登录")])])
}]

export { render, staticRenderFns }